import { OptionsIcon } from 'assets/icons/OptionsIcon';
import axios from 'axios';
import { ModalDelete } from 'components/ModalDelete/ModalDelete';
import { NotificacionI } from 'interfaces/Notificacion';
import { useState } from 'react';
import { Badge, Dropdown, Spinner } from 'react-bootstrap';
import { ENDPOINTS } from 'services/endpoints';
import { getReporte } from 'services/reportes';
import { sendRequest } from 'services/request/request.service';
import { servicesUtils } from 'services/utils/services.utils';
import { formatDate } from 'utils/formatUtils';
interface Props {
    notificacion: NotificacionI
    handleShowDetailModal: (id: number | string) => void,
    handleDelete: (id: number | string) => void,
    handleEdit: (notificacion: NotificacionI) => void,
    startLoading: () => void, 
    stopLoading: () => void
}

export const NotificacionesRow = ({ notificacion, handleShowDetailModal, handleDelete, handleEdit, startLoading,stopLoading
 }: Props) => {
    const [isLoading, setIsLoading] = useState(false)

    const downloadExcel = async (id:string) => {
        try{
            startLoading();  
            const response = await getReporte(id);
            // const response = await sendRequest<any>({
            //     method: "GET_FILE",
            //     uri: ENDPOINTS.REPORTS_CREATE + id,
            //     params: servicesUtils.removeNullParamsInFilters({}),
            // })
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'datos.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (err) {
            console.error("Hubo un problema al descargar el excel", err);
        } finally {
            stopLoading();  
        }
    };


    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleShowDeleteModal = () => {
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleConfirmDelete = () => {
        handleDelete(notificacion.id);
        handleCloseDeleteModal();
    };

    const handleReportDowload = (id:any) => {
        downloadExcel(id);
    };
    return (
        <>
            <tr className='table-row' key={`NotificacionesTable-${notificacion.title}`}>
                <td>{notificacion.title}</td>
                <td>{notificacion.notificationType?.name}</td>
                <td>{formatDate.stringDate(notificacion.sendAt)}</td>
                <td>
                    <Badge bg={"none"}
                        style={{
                            color: notificacion.notificationState?.color,
                            backgroundColor: notificacion.notificationState?.backgroundColor,
                            borderColor: notificacion.notificationState?.borderColor,
                        }}>
                        {notificacion.notificationState?.name}
                    </Badge>
                </td>
                <td>
                    <Dropdown className="acciones-dropdown | d-flex justify-content-center">
                        <Dropdown.Toggle>
                            <OptionsIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleShowDetailModal(notificacion.id)}>Ver notificacion</Dropdown.Item>
                            {notificacion.isEditable && <Dropdown.Item onClick={() => handleEdit(notificacion)}>Editar</Dropdown.Item>}
                            {notificacion.isEditable && <Dropdown.Item onClick={handleShowDeleteModal}>Eliminar</Dropdown.Item>}
                            {(notificacion.notificationState.id == 2) &&<Dropdown.Item onClick={() => handleReportDowload(notificacion.id)}>Reporte</Dropdown.Item>}
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>

            {isLoading && (
                <div className="overlay">
                    <Spinner animation="border" role="status" className="spinner-center" />
                </div>
            )}

            <ModalDelete
                showModal={showDeleteModal}
                handleCloseModal={handleCloseDeleteModal}
                handleConfirmDelete={handleConfirmDelete}
                isUserDelete={false}
            />
        </>
    )
}
