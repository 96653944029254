import { ENDPOINTS } from "./endpoints"
import { sendRequest } from "./request/request.service"
import { servicesUtils } from "./utils/services.utils"

export const getReporte = async (id: number | string) => {
    const elemento = await sendRequest<any>({
        method: "GET_FILE",
        uri: ENDPOINTS.REPORTS_CREATE + id,
        params: servicesUtils.removeNullParamsInFilters({}),
        
    })
    return elemento 
}

